import React from 'react'

export const Logo = ({ color = '#FFFFFF' }) => (
  <svg width="165" height="42" viewBox="0 0 165 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className='bg' d="M21.0011 0C9.40261 0 0 9.4026 0 21.0011C0 32.5995 9.40261 42.0021 21.0011 42.0021H66.8779C78.4763 42.0021 87.8789 32.5995 87.8789 21.0011C87.8789 9.4026 78.4763 0 66.8779 0H21.0011Z" fill={color}/>
    <path className='bg' d="M164.772 0H87.8789V42H164.772V0Z" fill={color}/>
    <path d="M63.6372 22.4969C63.8117 20.201 65.1458 18.6307 67.5417 18.6307C69.9376 18.6307 71.21 20.1776 71.2334 22.4969H63.6372ZM61.4669 23.6203C61.4669 27.9482 63.8373 30.2802 67.5672 30.2802C71.2972 30.2802 72.793 27.9354 73.0675 26.3758V26.0013H71.11C70.91 27.2248 69.8759 28.6206 67.5545 28.6206C65.1097 28.6206 63.65 26.8865 63.6011 24.0927H73.3441V23.0692C73.3441 19.3265 71.31 16.9689 67.5566 16.9689C63.8032 16.9689 61.4691 19.301 61.4691 23.6182M56.9688 23.631C56.9688 26.7864 55.3219 28.5333 52.9027 28.5333C50.4834 28.5333 48.8365 26.7864 48.8365 23.631C48.8365 20.4755 50.4834 18.7158 52.9027 18.7158C55.3219 18.7158 56.9688 20.4627 56.9688 23.631ZM59.0902 29.9802V12.0197H56.8944V18.9924H56.6454C56.0837 18.0562 54.8623 16.9838 52.4537 16.9838C49.1472 16.9838 46.6172 19.2286 46.6172 23.6331C46.6172 28.0376 49.1493 30.2696 52.4537 30.2696C54.8602 30.2696 56.122 29.1972 56.7071 28.1737H56.9561V29.9824H59.0902V29.9802ZM34.3826 23.631C34.3826 20.401 36.0422 18.6797 38.5104 18.6797C40.9787 18.6797 42.6383 20.401 42.6383 23.631C42.6383 26.8609 40.9787 28.5695 38.5104 28.5695C36.0422 28.5695 34.3826 26.8482 34.3826 23.631ZM32.1505 23.631C32.1505 27.9099 34.7209 30.2802 38.5126 30.2802C42.3042 30.2802 44.8746 27.9099 44.8746 23.631C44.8746 19.352 42.3042 16.9711 38.5126 16.9711C34.7209 16.9711 32.1505 19.3414 32.1505 23.631ZM14.5411 20.9989C14.5411 26.8247 18.0222 30.2802 23.0118 30.2802C28.0014 30.2802 30.1717 26.9503 30.4717 24.231V23.8565H28.3014C28.0652 25.9013 26.6311 28.4333 23.0245 28.4333C19.418 28.4333 16.837 25.6523 16.837 20.9989C16.837 16.3455 19.369 13.5645 23.0245 13.5645C26.6801 13.5645 28.0503 15.8348 28.2503 17.9562H30.4079V17.5817C30.159 14.8135 27.9376 11.7197 23.0118 11.7197C18.086 11.7197 14.5411 15.1625 14.5411 21.001" fill="black"/>
    <path d="M144.752 20.5732C144.752 22.7818 146.424 23.767 148.744 24.1649L150.791 24.5011C152.399 24.7628 153.51 25.199 153.51 26.4841C153.51 27.7693 152.425 28.6055 150.316 28.6055C148.207 28.6055 146.699 27.7204 146.55 26.0352H144.492V26.4097C144.805 28.7289 146.888 30.2141 150.318 30.2141C153.748 30.2141 155.608 28.7289 155.608 26.348C155.608 23.967 153.825 23.1542 151.303 22.7308L149.259 22.3946C147.924 22.169 146.852 21.7328 146.852 20.486C146.852 19.2391 147.999 18.5284 149.882 18.5284C151.765 18.5284 153.063 19.2391 153.163 20.8732H155.197V20.4987C154.948 18.3412 153.001 16.9177 149.846 16.9177C146.69 16.9177 144.756 18.2646 144.756 20.5732M132.588 23.567C132.588 20.3987 134.234 18.6518 136.666 18.6518C139.099 18.6518 140.733 20.3987 140.733 23.567C140.733 26.7352 139.086 28.4693 136.666 28.4693C134.247 28.4693 132.588 26.7224 132.588 23.567ZM130.479 17.2049V34.9058H132.675V28.1949H132.924C133.485 29.1311 134.707 30.2035 137.115 30.2035C140.422 30.2035 142.941 27.9587 142.941 23.567C142.941 19.1753 140.422 16.9177 137.115 16.9177C134.709 16.9177 133.449 17.9901 132.862 19.0135H132.613V17.2049H130.481H130.479ZM125.298 29.9162H127.494V17.2049H125.298V29.9162ZM125.26 14.8984H127.517V12.2792H125.26V14.8984ZM116.919 17.2049V29.9162H119.115V22.8308C119.115 19.637 120.861 18.7135 123.493 18.988V17.0922C120.887 16.8305 119.838 17.9156 119.302 19.0008H119.053V17.2049H116.921H116.919ZM101.792 13.775H105.746C109.637 13.775 112.069 16.4432 112.069 20.9349C112.069 25.4266 109.637 28.0949 105.746 28.0949H101.792V13.775ZM99.5093 11.9536V29.9162H105.784C110.974 29.9162 114.365 26.499 114.365 20.9349C114.365 15.3708 110.972 11.9536 105.784 11.9536H99.5093Z" fill="black"/>
  </svg>
)

export const RightArrow = () => (
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 5.7002H15.4997V7.69988H0V5.7002Z" fill="black"/>
    <path d="M11.2003 13.4006L9.79968 12L15.1003 6.7003L9.79968 1.40062L11.2003 0L17.8997 6.70032L11.2003 13.4006Z" fill="black"/>
  </svg>
)

export const WhiteCorner = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <path d="M30,0H0V30C0,13.43,13.43,0,30,0Z" fill={'#FFFFFF'}/>
  </svg>
)

export const SelectArrow = () => (
  <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.49994 7L7.00864 6.51933L12.5 1.32012L11.4826 8.64733e-07L6.5 4.7185L1.51737 -6.45751e-09L0.5 1.32012L5.99136 6.51933L6.49994 7Z" fill="black"/>
  </svg>
)