import React from 'react'
import Link from '../utils/link'
import { Logo } from './icons'

const Header = ({ data, location }) => {

  const [offCanvas, setOffCanvas] = React.useState(false)
  const [scrolled, setScrolled] = React.useState(false)

  React.useEffect(() => {
    let isScrolling = false

    if (window?.innerWidth > 900) {
      setTimeout(() => {
        setOffCanvas(true)
      }, 1000)
    }

    const onScroll = (e) => {
      const curScroll = window.scrollY || document.scrollTop
      requestAnimationFrame(() => {
        setScrolled(curScroll > 1)
      })
    }

    const scrollDetect = () => {
      window.clearTimeout(isScrolling)
      isScrolling = setTimeout(onScroll, 0);
    }

    window.addEventListener('scroll', scrollDetect)

    return () => {
      window.removeEventListener('scroll', scrollDetect)
    }
  }, [])

  const hideOffCanvas = () => {
    setOffCanvas(false)
  }

  const toggleOffCanvas = () => {
    setOffCanvas(!offCanvas)
  }

  let props = {
    onClick: hideOffCanvas,
    activeClassName: 'active'
  }

  let headerClass = 'header'
  if (scrolled) headerClass += ' header--scrolled'
  if (offCanvas) headerClass += ' header--menu-active'

  return (
    <>
      <header className={headerClass}>
        <div className='header__inner'>
          <Link to='/' title='CodeDrips' className='header__logo' {...props}>
            <Logo color='#FF4200' />
          </Link>
          <nav className={offCanvas ? 'header__nav active' : 'header__nav'} >
            <ul>
              { data?.headerMenu?.menuItems?.nodes?.map(({ label, uri }, i) => (
                <li key={i}><Link to={uri} {...props}>{ label }</Link></li>
              ))}
            </ul>
            <button
              type='button'
              onClick={toggleOffCanvas}
              className='header__hamburger'
              aria-label="Mobile Navigation" 
            />
          </nav>
        </div>
      </header>
      { location?.pathname !== '/' && <div className='header__spacer' /> }
      <div className={`off-canvas ${offCanvas && 'off-canvas--active'}`}>
        <div className='off-canvas__inner'>
          <nav className='off-canvas__nav'>
            <ul>
              { data?.headerMenu?.menuItems?.nodes?.map(({ label, uri }, i) => (
                <li key={i}><Link to={uri} {...props}>{ label }</Link></li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Header