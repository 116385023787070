import React, { Component } from 'react'
import Link from '../utils/link'
import { RightArrow } from './icons'

class Footer extends Component {
  render() {
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__col'>
            <h4>Start your next project with us.</h4>
            <ul>
              <li>
                <Link to='/contact/' className='btn btn--white'>
                  Start here
                  <RightArrow />
                </Link>
              </li>
              <li>
                <Link to='mailto:hello@codedrips.com' className='btn btn--white'>
                  or email us instead
                  <RightArrow />
                </Link>
              </li>
            </ul>
          </div>
          <div className='footer__col'>
            <ul>
              <li><Link to='/project/'>Projects</Link></li>
              <li><Link to='/service/'>Services</Link></li>
              <li><Link to='/about/'>About</Link></li>
              <li><Link to='/contact/'>Contact</Link></li>
            </ul>
            <ul>
              <li>
                <Link to='https://github.com/CodeDrips'>
                  GitHub
                  <RightArrow />
                </Link>
              </li>
              <li>
                <Link to='https://au.linkedin.com/company/codedrips'>
                  LinkedIn
                  <RightArrow />
                </Link>
              </li>
              <li>
                <Link to='https://instagram.com/codedrips'>
                  Instagram
                  <RightArrow />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className='footer__inner'>
          <div className='footer__col'>
            <p>© Codedrips {new Date().getFullYear()}. All rights reserved.</p>
          </div>
          <div className='footer__col'>
            <p></p>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
